import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {ImportPage} from "../modules/import/ImportPage";

const PrivateRoutes = () => {

    const AccountPage = lazy(() => import('../pages/account/AccountWrapper'))
    const UsersPage = lazy(() => import('../pages/users/UsersWrapper'))
    const RolesPage = lazy(() => import('../pages/roles/RolesWrapper'))
    const CurrenciesPage = lazy(() => import('../pages/currencies/CurrenciesWrapper'))
    const CountriesPage = lazy(() => import('../pages/countries/CountriesWrapper'))
    const DownloadersPage = lazy(() => import('../pages/downloaders/DownloadersWrapper'))
    const ImportPage = lazy(() => import('../pages/imports/ImportWrapper'))
    const PackageRunInfoPage = lazy(() => import('../pages/packageRunInfo/PackageRunInfoWrapper'))
    const ParsersPage = lazy(() => import('../pages/parsers/ParsersWrapper'))
    const ServersPage = lazy(() => import('../pages/servers/ServersWrapper'));
    const RevaluationPage = lazy(() => import('../pages/revaluation/RevaluationWrapper'))
    const AssemblyPage = lazy(() => import('../pages/assembly/AssemblyWrapper'))
    const CalendarEventsPage = lazy(() => import('../pages/calendarEvents/CalendarEventsWrapper'))
    const PackagesPage = lazy(() => import('../pages/packages/PackagesWrapper'))
    const ProducersPage = lazy(() => import('../pages/producers/ProducersWrapper'))
    const ItemsPage = lazy(() => import('../pages/items/ItemsWrapper'))
    const ConcurencyPage = lazy(() => import('../pages/concurency/ConcurencyWrapper'))
    const IgnoredItemsPage = lazy(() => import('../pages/ignoredItems/IgnoredItemsWrapper'))
    const ConcurencyGroupsPage = lazy(
        () => import('../pages/concurencyGroups/ConcurencyGroupsWrapper')
    )
    const ConcurencyLinkedNamesPage = lazy(() => import('../pages/concurencyLinkedNames/ConcurencyLinkedNamesWrapper'))
    const CompaniesPage = lazy(() => import('../pages/companies/CompaniesWrapper'))
    const ReportsPage = lazy(() => import('../pages/reports/ReportsWrapper'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Revaluation after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/revaluation' />} />
                {/* Redirect to Revaluation when homepage */}
                <Route path='/' element={<Navigate to='/revaluation' />} />
                {/* Pages */}
                {/* Lazy Modules */}
                <Route
                    path='/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/user-management/users/*'
                    element={
                        <SuspensedView>
                            <UsersPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/user-management/roles/*'
                    element={
                        <SuspensedView>
                            <RolesPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/country-management/currencies/*'
                    element={
                        <SuspensedView>
                            <CurrenciesPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/country-management/countries/*'
                    element={
                        <SuspensedView>
                            <CountriesPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/download-management/downloaders/*'
                    element={
                        <SuspensedView>
                            <DownloadersPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/imports/:path/*'
                    element={
                        <SuspensedView>
                            <ImportPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/download-management/package-run-info/*'
                    element={
                        <SuspensedView>
                            <PackageRunInfoPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='/download-management/parsers/*'
                    element={
                        <SuspensedView>
                            <ParsersPage />
                        </SuspensedView>
                    }
                />
                <Route

                    path='/download-management/servers/*'
                    element={
                        <SuspensedView>
                            <ServersPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/download-management/calendar-events/*'
                    element={
                        <SuspensedView>
                            <CalendarEventsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/download-management/packages/*'
                    element={
                        <SuspensedView>
                            <PackagesPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/producers/*'
                    element={
                        <SuspensedView>
                            <ProducersPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/items/*'
                    element={
                        <SuspensedView>
                            <ItemsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/concurency-management/groups/*'
                    element={
                        <SuspensedView>
                            <ConcurencyGroupsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/concurency-management/concurency/*'
                    element={
                        <SuspensedView>
                            <ConcurencyPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/concurency-management/companies/*'
                    element={
                        <SuspensedView>
                            <CompaniesPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/concurency-management/ignored-items/*'
                    element={
                        <SuspensedView>
                            <IgnoredItemsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/assembly/:packageId/:countryId/*'
                    element={
                        <SuspensedView>
                            <AssemblyPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='/concurency-management/linked-names/*'
                    element={
                        <SuspensedView>
                            <ConcurencyLinkedNamesPage />
                        </SuspensedView>
                    }
                />
                <Route path='/revaluation'>
                    <Route
                        index
                        element={
                            <SuspensedView>
                                <RevaluationPage />
                            </SuspensedView>
                        }
                    />
                    <Route
                        path=':packageId/:countryId'
                        element={
                            <SuspensedView>
                                <RevaluationPage />
                            </SuspensedView>
                        }
                    />
                </Route>
                <Route
                    path='/reports/*'
                    element={
                        <SuspensedView>
                            <ReportsPage />
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
